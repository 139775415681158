<template>
  <div>
    <b-col md="12">
      <b-card
        title="จัดการบัญชีธนาคาร"
      >
        <b-form @submit.prevent>
          <b-row>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="info"
                class="mb-1"
                @click="addnewBankAccount"
              >
                เพิ่มบัญชีใหม่
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <hr>
        <h2>บัญชีฝาก</h2>
        <b-table
          key="depositBanks"
          ref="bankAccountTable"
          striped
          hover
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :per-page="tableQuery.size"
          :current-page="tableQuery.page"
          :items="bankaccounts.filter(i => i.acc_type == 'IN')"
          :fields="bankaccountsFields"
        >
          <template #cell(bank)="data">
            <div class="text-center">
              <img
                :src="getBankImage(data.item.bank_code)"
                style="width: 24px;"
              ><br>
              {{ `${{'004':'กสิกรไทย', '006':'กรุงไทย', '014': 'ไทยพาณิชย์', 'tmw': 'ทรูวอลเล็ท', 'TMW': 'ทรูวอลเล็ท'}[data.item.bank_code]}` }}
            </div>
          </template>
          <template #cell(acc_name)="data">
            <div>
              <b-badge variant="light-primary">{{ data.item.bank_alias || data.item.queue_name }}</b-badge><br>
              {{ `${data.item.acc_name}` }}
            </div>
          </template>
          <template #cell(active)="data">
            <b-badge
              pill
              :variant="data.item.active == 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.active == 'Y' ? 'เปิด':'ปิด' }}
            </b-badge>
          </template>
          <template #cell(bot_sms)="data">
            <b-badge
              pill
              :variant="data.item.bot_sms === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.bot_sms === 'Y' ? 'จับยอด':'ไม่จับยอด' }}
            </b-badge>
            |
            <b-badge
              pill
              :variant="data.item.auto_sms === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.auto_sms === 'Y' ? 'ออโต้':'ไม่ออโต้' }}
            </b-badge>
          </template>
          <template #cell(bot_enet)="data">
            <b-badge
              pill
              :variant="data.item.bot_enet === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.bot_enet === 'Y' ? 'จับยอด':'ไม่จับยอด' }}
            </b-badge>
            |
            <b-badge
              pill
              :variant="data.item.auto_enet === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.auto_enet === 'Y' ? 'ออโต้':'ไม่ออโต้' }}
            </b-badge>
          </template>
          <template #cell(bot_app)="data">
            <b-badge
              pill
              :variant="data.item.bot_app === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.bot_app === 'Y' ? 'จับยอด':'ไม่จับยอด' }}
            </b-badge>
            |
            <b-badge
              pill
              :variant="data.item.auto_app === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.auto_app === 'Y' ? 'ออโต้':'ไม่ออโต้' }}
            </b-badge>
          </template>
          <template #cell(bot_statement)="data">
            <b-form-checkbox
              checked="true"
              class="custom-control-success"
              name="check-button"
              switch
              value="Y"
              unchecked-value="N"
              v-model="data.bot_statement"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </template>
          <template #cell(public)="data">
            <b-badge
              pill
              :variant="data.item.public === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.public === 'Y' ? 'แสดงหน้าเว็บ':'ไม่แสดงหน้าเว็บ' }}
            </b-badge>
          </template>
          <template #cell(balance)="data">
            <div class="text-center">
              {{ numberWithCommas(data.item.balance || 0) }}<br>
              เมื่อ {{ formatDate(data.item.updated_at) }}
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              style="margin-top: 10px; margin-right: 10px;"
              variant="outline-primary"
              size="sm"
              @click="editBankAccount(data.item)"
            >
              แก้ไข
            </b-button>
          </template>
        </b-table>
        <hr>
        <h2>บัญชีถอน</h2>
        <b-table
          key="withdrawBanks"
          ref="withdrawTable"
          striped
          hover
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :per-page="tableQuery.size"
          :current-page="tableQuery.page"
          :items="bankaccounts.filter(i => i.acc_type == 'OUT')"
          :fields="bankaccountsFields"
        >
          <template #cell(bank)="data">
            <div class="text-center">
              <img
                :src="getBankImage(data.item.bank_code)"
                style="width: 24px;"
              ><br>
              {{ `${{'004':'กสิกรไทย', '006':'กรุงไทย', '014': 'ไทยพาณิชย์', 'tmw': 'ทรูวอลเล็ท', 'TMW': 'ทรูวอลเล็ท'}[data.item.bank_code]}` }}
            </div>
          </template>
          <template #cell(acc_name)="data">
            <div>
              <b-badge variant="light-primary">{{ data.item.bank_alias || data.item.queue_name }}</b-badge><br>
              {{ `${data.item.acc_name}` }}
            </div>
          </template>
          <template #cell(active)="data">
            <b-badge
              pill
              :variant="data.item.active === 'Y' ? 'light-success':'light-danger'"
            >
              {{ data.item.active === 'Y' ? 'เปิด':'ปิด' }}
            </b-badge>
          </template>
          <template #cell(balance)="data">
            <div class="text-center">
              {{ numberWithCommas(data.item.balance || 0) }}<br>
              เมื่อ {{ formatDate(data.item.balance_at) }}
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              style="margin-top: 10px; margin-right: 10px;"
              variant="outline-primary"
              size="sm"
              @click="editBankAccount(data.item)"
            >
              แก้ไข
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BButton, BCardBody, BRow, BCol, BForm, BPagination, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    // vSelect,
    // flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankaccounts: [],
      statementItems: [],
      isBusy: false,
      refRecheck: null,
      pageOptions: [100, 200, 500, 1000],
      totalRows: 1,
      tableQuery: {
        page: 1,
        size: 100,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      bankaccountsFields: [
        { key: 'id', label: '#' },
        { key: 'bank', label: 'ธนาคาร', tdClass: 'text-center' },
        { key: 'acc_no', label: 'เลขที่บัญชี' },
        { key: 'acc_name', label: 'รายละเอียด' },
        { key: 'balance', label: 'ยอดปัจจุบัน' },
        { key: 'active', label: 'สถานะ' },
        { key: 'bot_sms', label: 'ออโต้จาก SMS' },
        { key: 'bot_enet', label: 'ออโต้จาก ENET' },
        { key: 'bot_app', label: 'ออโต้จาก APP' },
        { key: 'public', label: 'แสดงบนหน้าเว็บ' },
        { key: 'actions', label: 'จัดการ' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchBankAccounts()
  },
  methods: {
    clearSearch() {
      this.tableQuery = {
        page: 1,
        size: 100,
      }
      this.statementItems = []
    },
    async matchUser(bankTransferData) {
      console.log(bankTransferData)
    },
    async addnewBankAccount() {
      this.$router.push({ name: 'manage-bankaccount-detail', params: { bankaccount: 'add-new-bank' } })
    },
    async editBankAccount(data) {
      this.$router.push({ name: 'manage-bankaccount-detail', params: { bankaccount: data.id } })
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts?mode=v2')
      this.bankaccounts = _bankaccounts.bankaccounts
      // .map(i => ({ ...i, title: `${i.bank} ${i.acc_no} ${i.acc_name}` }))
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'DEPOSIT-TMW', acc_name: 'ฝากทรูมันนี่',
      // })
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'WITHDRAW-TMW', acc_name: 'ถอนทรูมันนี่', acc_type: 'OUT', queue_name: 'TMW',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deposit-manual', acc_name: 'ฝากมือ',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'withdraw-manual', acc_name: 'ถอนมือ', acc_type: 'OUT', queue_name: 'M',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deduction', acc_name: 'ตัดเครดิต/ยึดเครดิต', acc_type: 'DEDUCTION',
      // })
      // this.bankaccounts
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
